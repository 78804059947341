<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-custom-object-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "CustomObjects.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                @keyup="customObjectNameKeyup()"
                v-model="customObjectData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="PluralName" class="form-label required">{{
                $t(
                  "CustomObjects.PluralName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="PluralName"
                v-model="customObjectData.pluralName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "CustomObjects.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                @keyup="formulaNameKeyup()"
                v-model="customObjectData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="ilkAlanTipi" class="form-label required">{{
                $t(
                  "CustomObjects.FirstFieldType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :selectedData="selectedCustomObjectType"
                :allowEmpty="false"
                :data="ilkAlanTipiData"
                @onChange="onChangeForIlkAlanTipi"
              />
            </div>
            <div class="col-md-6">
              <label for="RequiredFieldName" class="form-label required">{{
                $t(
                  "CustomObjects.RequiredFieldName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="RequiredFieldName"
                @keyup="requiredFieldNameKeyup()"
                v-model="customObjectData.requiredFieldName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label
                for="RequiredFieldFormulaName"
                class="form-label required"
                >{{
                  $t(
                    "CustomObjects.RequiredFieldFormulaName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
              <input
                type="text"
                class="form-control text-uppercase"
                id="RequiredFieldFormulaName"
                @keyup="requiredFieldFormulaNameKeyup()"
                v-model="customObjectData.requiredFieldFormulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6" v-if="!ilkAlanTipiMetinAlani">
              <label for="requiredFieldFormula" class="form-label required">{{
                $t(
                  "CustomObjects.Formula",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="requiredFieldFormula"
                v-model="customObjectData.requiredFieldFormula"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-12">
              <div class="form-check" v-if="!ilkAlanTipiMetinAlani">
                <label for="IsCalculatedOnlyOnInsert" class="form-label">{{
                  $t(
                    "CustomObjects.IsCalculatedOnlyOnInsert",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsCalculatedOnlyOnInsert"
                  v-model="customObjectData.isCalculatedOnlyOnInsert"
                />
              </div>
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="customObjectData.isActive"
                />
              </div>
              <div class="form-check">
                <label for="IsRecordsCopied" class="form-label">{{
                  $t(
                    "CustomObjects.RecordCopiedIsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsRecordsCopied"
                  v-model="customObjectData.isRecordsCopied"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "CustomObjectNew",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      customObjectData: {
        isActive: true,
      },
      isSuccess: false,
      ilkAlanTipiMetinAlani: true,
      ilkAlanTipiData: [
        {
          key: "true",
          value: this.$t(
            "CustomObjects.TextField",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "false",
          value: this.$t(
            "CustomObjects.FormulaField",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedCustomObjectType: [
        {
          key: "true",
          value: this.$t(
            "CustomObjects.TextField",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onClear() {
      this.customObjectData = {
        isActive: true,
      };
      $(".form-custom-object-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-custom-object-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CustomObjectNew", { ...this.customObjectData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/Customobject/Detail/" + response.publicId);
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    onChangeForIlkAlanTipi: function (selected) {
      if (String.isNullOrWhiteSpace(selected.key)) {
        this.ilkAlanTipiMetinAlani = true;
        return;
      }
      this.ilkAlanTipiMetinAlani = Boolean.toBool(selected.key);
    },
    customObjectNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.customObjectData.name)) {
        this.customObjectData.formulaName = "";
        return;
      }
      this.customObjectData.formulaName = String.replaceAlphanumeric(
        this.customObjectData.name,
        "_"
      ).toUpperCase();
    },
    formulaNameKeyup() {
      this.customObjectData.formulaName = String.replaceAlphanumeric(
        this.customObjectData.formulaName,
        "_"
      ).toUpperCase();
    },
    requiredFieldFormulaNameKeyup() {
      this.customObjectData.requiredFieldFormulaName =
        String.replaceAlphanumeric(
          this.customObjectData.requiredFieldFormulaName,
          "_"
        ).toUpperCase();
    },
    requiredFieldNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.customObjectData.requiredFieldName)) {
        this.customObjectData.requiredFieldFormulaName = "";
        return;
      }
      this.customObjectData.requiredFieldFormulaName =
        String.replaceAlphanumeric(
          this.customObjectData.requiredFieldName,
          "_"
        ).toUpperCase();
    },
  },
};
</script>
